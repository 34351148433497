import '@fontsource-variable/dm-sans';
import '@fontsource/dm-serif-display';
import '@fontsource-variable/alegreya';
import '@fontsource/montez';
import '@fontsource-variable/source-sans-3';
import '@fontsource-variable/montserrat';
import '@fontsource-variable/work-sans';
import '@fontsource/merriweather';
import '@fontsource/style-script';
import '@fontsource/palanquin';

import 'prismjs/themes/prism.css';
import netlifyIdentity from 'netlify-identity-widget';

import './src/styles/style.scss';

netlifyIdentity.init();
